import { of } from 'rxjs';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { NatsSiteData } from '@nats/models';
import { GLOBAL_CONFIG } from '@nats/tokens';
import { createRouterRoutes } from './create-routes';

export function provideNatsRoutesInitializer(): Provider {
	return {
		provide: APP_INITIALIZER,
		useFactory: routesExistFactory,
		multi: true,
		deps: [GLOBAL_CONFIG, Router],
	};
}

export function routesExistFactory(config: NatsSiteData, router: Router) {
	router.resetConfig(createRouterRoutes(config.pages));
	return () => of(true);
}
