import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	Injector,
	Type,
	untracked,
} from '@angular/core';
import { AsyncPipe, DOCUMENT, NgComponentOutlet } from '@angular/common';

import { LAZY_BLOCKS_MAP } from '../blocks/blocks-map';

import {
	BlockConfig,
	EMPTY_PAGE_DESIGN_LAYOUT,
	EMPTY_PAGE_HIDDEN_BLOCKS,
	isModelViewPage,
	isSetViewPage,
	NatsBlock,
	NatsPageData,
	SizeScreenType,
} from '@nats/models';
import {
	API_URL,
	BLOCK_DATA,
	CONFIG_TOKEN,
	GLOBAL_CONFIG,
	PAGE_CUSTOM_DATA,
	PAGE_DATA,
	PAGE_DESIGN_LAYOUT,
} from '@nats/tokens';
import { injectScreenSize } from '@nats/shared/injections';
import {
	addStyleTagToHead,
	createPageGridLayoutStyles,
	getRowOrderNumberOfBlocks,
} from '@nats/shared/functions';
import { UserConsentService } from '../core/user-consent.service';
import { ConsentModalComponent } from '../helpers/consent-modal.component';
import { LoadingSpinner } from './loading-spinner.component';
import { PreviewModeBannerComponent } from '@nats/shared/components';
import { PreviewDataStore } from '@nats/shared/services';
import { GlobalMetadata, NgxMetaService } from '@davidlj95/ngx-meta/core';
import { StandardMetadata } from '@davidlj95/ngx-meta/standard';
import { injectRouteData } from 'ngxtension/inject-route-data';
import { CookieBannerService } from '../core/cookie-banner.service';
import { CookieBannerModal } from '../helpers/cookie-banner-modal/cookie-banner-modal.component';

// Global variable that will be used to
// store the ids of the pages whose styles have already been added to the DOM
const LOADED_PAGE_STYLES = new Set<string>();

@Component({
	selector: 'nats-component-loader',
	template: `
		@if (previewDataStore.isPreviewMode()) {
			@defer (prefetch on immediate) {
				<nats-preview-mode-banner (closed)="previewDataStore.closePreviewMode()" />
			}
		}

		@if (userConsent.showModal()) {
			@defer (when userConsent.showModal()) {
				<nats-consent-modal />
			}
		}

		@if (cookieBanner.showModal() && !userConsent.showModal()) {
			@defer (when cookieBanner.showModal()) {
				<nats-cookie-banner-modal />
			}
		}

		<div
			class="components-wrapper {{ pageWidthCssClass() }}"
			[style.filter]="userConsent.showModal() ? 'blur(20px)' : ''">
			<div style="display: grid; grid-template-columns: repeat(12, 1fr)">
				@for (block of blocks(); track block.id) {
					<div
						id="block-{{ block.id }}-page-{{ block.pageId }}-layout"
						class="block bid-{{ block.id }}"
						[class.sticky-block-bottom]="block.sticky.isBottom"
						[class.sticky-block-top]="block.sticky.isTop">
						@if (block.component) {
							<div class="section">
								<ng-container
									[ngComponentOutlet]="block.component"
									[ngComponentOutletInjector]="block.dataInjector" />
							</div>
						} @else {
							@if (block.componentImport | async; as cmp) {
								<div class="section">
									<ng-container
										[ngComponentOutlet]="cmp"
										[ngComponentOutletInjector]="block.dataInjector" />
								</div>
							} @else {
								<nats-loading-spinner [minHeight]="block.minHeight" />
							}
						}
					</div>
				}
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AsyncPipe,
		NgComponentOutlet,
		ConsentModalComponent,
		LoadingSpinner,
		PreviewModeBannerComponent,
		CookieBannerModal,
	],
})
export class ComponentLoader {
	private configData = inject(CONFIG_TOKEN);
	private siteData = inject(GLOBAL_CONFIG);
	private injector = inject(Injector);
	private document = inject(DOCUMENT);
	private ngxMetaService = inject(NgxMetaService);

	protected previewDataStore = inject(PreviewDataStore);
	protected userConsent = inject(UserConsentService);
	protected cookieBanner = inject(CookieBannerService);

	// current screen size based on the media queries
	currentScreenSize = injectScreenSize();

	// page data will come from resolver, will be set when we initialize the routes and will be of type RoutePageData
	pageData = injectRouteData<NatsPageData>('page');

	readonly pageWidthCssClass = computed(() => {
		const pageData = this.pageData();
		if (!pageData?.settings) return '';
		if (pageData.settings.page_width === 'contained') return 'container';
		if (pageData.settings.page_width === 'full') return 'container-fluid';
		return '';
	});

	readonly blocks = computed<BlockConfig[]>(() => {
		const pageData = this.pageData();
		if (!pageData) return [];

		const currentScreenSize = this.currentScreenSize();

		return this.getBlocks(pageData, currentScreenSize);
	});

	constructor() {
		effect(() => {
			const pageData = this.pageData();
			if (pageData) {
				untracked(() => this.setupPageDetails(pageData));
			}
		});
	}

	private setupPageDetails(pageData: NatsPageData) {
		const { body_class: pageBodyClass, body_id: pageBodyId } = pageData.settings || {};
		const { body_tag_id: themeBodyId, body_tag_class: themeBodyClass } =
			this.siteData.theme?.settings || {};

		// Page ID overrides Theme ID
		this.document.body.id = pageBodyId || themeBodyId || '';
		// Page Class appends to Theme Class
		this.document.body.className = `${themeBodyClass || ''} ${pageBodyClass || ''}`.trim();

		this.userConsent.showIfNotApproved(pageData);

		this.setSEOMetadata(pageData);
	}

	private getBlocks(pageData: NatsPageData, currentScreenSize: SizeScreenType) {
		const blocksData = pageData['blocks'];
		if (!blocksData) return [];

		const designLayout = pageData['settings']?.design_layout;

		const layouts = designLayout?.layouts || EMPTY_PAGE_DESIGN_LAYOUT;
		const pageHiddenBlocks = designLayout?.hidden_blocks || EMPTY_PAGE_HIDDEN_BLOCKS;

		const visiblePageBlocks = blocksData.filter(block => {
			// show only enabled blocks
			if (block.settings.enabled !== 'yes') return false;

			// show only blocks that are not hidden
			if (pageHiddenBlocks[currentScreenSize]?.includes(block.cms_block_id)) return false;

			return true;
		});

		const blocksOrder = getRowOrderNumberOfBlocks(layouts);

		const getBlockOrderNumber = (blockId: string) =>
			blocksOrder[currentScreenSize].find(x => x.id === blockId)?.order || 0;

		const blocksWithData = visiblePageBlocks
			.map(block => {
				const { component, componentImport, minHeight } = createComponentImport(
					block.settings.type
				);

				return {
					id: block.cms_block_id,
					pageId: pageData.cms_page_id,
					order: getBlockOrderNumber(block.cms_block_id),
					sticky: {
						isTop: block.settings.row_helper_class_sticky === 'enabled_top',
						isBottom: block.settings.row_helper_class_sticky === 'enabled_bottom',
					},
					dataInjector: this.createInjector(pageData, block, visiblePageBlocks),
					componentImport,
					component,
					minHeight,
				} as BlockConfig;
			})
			.sort((a, b) => a.order - b.order);

		const pageGridStyles = createPageGridLayoutStyles(
			layouts,
			blocksWithData,
			pageHiddenBlocks,
			pageData.cms_page_id
		);

		// if the styles for the current page have not been added to the DOM we add them
		if (!LOADED_PAGE_STYLES.has(pageData.cms_page_id)) {
			addStyleTagToHead(pageGridStyles, `page-${pageData.cms_page_id}-grid-styles`);
			LOADED_PAGE_STYLES.add(pageData.cms_page_id);
		}

		return blocksWithData;
	}

	private createInjector(
		pageData: NatsPageData,
		blockData: NatsBlock,
		pageBlocks: NatsBlock[]
	): Injector {
		return Injector.create({
			parent: this.injector,
			providers: [
				// here we can provide also other data that can be needed by components
				// in order for the components to be aware of the page
				// example: auth data, whole page config, page data
				// tokens for this kind on data should be at shared tokens library
				{ provide: PAGE_DATA, useValue: pageData },
				{ provide: BLOCK_DATA, useValue: blockData },
				{ provide: GLOBAL_CONFIG, useValue: this.siteData },
				{ provide: API_URL, useValue: this.configData.natsUrl },
				{ provide: PAGE_DESIGN_LAYOUT, useValue: pageData['settings']?.design_layout || {} },
				{
					provide: PAGE_CUSTOM_DATA,
					useValue: {
						isModelViewPage: isModelViewPage(pageBlocks),
						isSetViewPage: isSetViewPage(pageBlocks),
					},
				},
			],
		});
	}

	private setSEOMetadata(pageData: NatsPageData) {
		const { meta_title, meta_author, meta_description, meta_image_url, meta_keywords } =
			pageData.settings ?? {};

		this.ngxMetaService.set({
			title: meta_title || pageData.name,
			description: meta_description || undefined,
			standard: {
				keywords: meta_keywords?.split(',') || undefined,
				author: meta_author || undefined,
			},
			image: meta_image_url
				? {
						url: meta_image_url,
						alt: meta_title || pageData.name,
					}
				: undefined,
		} satisfies GlobalMetadata & StandardMetadata);
	}
}

// create component import based on type
export function createComponentImport(type?: string): {
	component?: Type<unknown>;
	componentImport: Promise<Type<unknown>>;
	minHeight?: number;
} {
	// if the type is not found in the map we use the not_found component
	const key = type && LAZY_BLOCKS_MAP[type] ? type : 'not_found';
	const { loader } = LAZY_BLOCKS_MAP[key];

	const alreadyLoadedComponent = LOADED_COMPONENTS[key];

	return {
		component: alreadyLoadedComponent,
		componentImport: loader().then(x => {
			if (!alreadyLoadedComponent) LOADED_COMPONENTS[key] = x;
			return x;
		}),
		minHeight: LAZY_BLOCKS_MAP[key].minHeight,
	};
}

/*
 * This will be used to store the already loaded components
 * so we don't have to show the loading spinner for each component that is already loaded
 * */
const LOADED_COMPONENTS: Record<string, Type<unknown>> = {};
