import * as i0 from '@angular/core';
import { VERSION, NgModule } from '@angular/core';
import { makeKeyValMetaDefinition, makeMetadataManagerProviderFromSetterFactory, NgxMetaMetaService, _GLOBAL_TITLE, _GLOBAL_DESCRIPTION, _GLOBAL_APPLICATION_NAME, _GLOBAL_CANONICAL_URL, _HEAD_ELEMENT_UPSERT_OR_REMOVE, _GLOBAL_LOCALE } from '@davidlj95/ngx-meta/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
const makeStandardMetaDefinition = makeKeyValMetaDefinition;
const STANDARD_KEY = 'standard';
const makeStandardMetadataProvider = (key, opts = {}) => makeMetadataManagerProviderFromSetterFactory(opts.s ?? (metaService => value => metaService.set(makeStandardMetaDefinition(opts.n ?? key), value)), {
  d: opts.d ?? [NgxMetaMetaService],
  jP: [STANDARD_KEY, key],
  g: opts.g
});

/**
 * @internal
 */
const __STANDARD_TITLE_METADATA_SETTER_FACTORY = titleService => value => {
  if (value === undefined || value === null) {
    return;
  }
  titleService.setTitle(value);
};
/**
 * Manages the {@link Standard.title} metadata
 * @public
 */
const STANDARD_TITLE_METADATA_PROVIDER = makeStandardMetadataProvider(_GLOBAL_TITLE, {
  g: _GLOBAL_TITLE,
  s: __STANDARD_TITLE_METADATA_SETTER_FACTORY,
  d: [Title]
});

/**
 * Manages the {@link Standard.description} metadata
 * @public
 */
const STANDARD_DESCRIPTION_METADATA_PROVIDER = makeStandardMetadataProvider(_GLOBAL_DESCRIPTION, {
  g: _GLOBAL_DESCRIPTION
});

/**
 * Manages the {@link Standard.author} metadata
 * @public
 */
const STANDARD_AUTHOR_METADATA_PROVIDER = makeStandardMetadataProvider('author');

/**
 * @internal
 */
const _STANDARD_KEYWORDS_KEY = 'keywords';
/**
 * @internal
 */
const __STANDARD_KEYWORDS_METADATA_SETTER_FACTORY = metaService => value => metaService.set(makeStandardMetaDefinition(_STANDARD_KEYWORDS_KEY), value?.join(','));
/**
 * Manages the {@link Standard.keywords} metadata
 * @public
 */
const STANDARD_KEYWORDS_METADATA_PROVIDER = makeStandardMetadataProvider(_STANDARD_KEYWORDS_KEY, {
  s: __STANDARD_KEYWORDS_METADATA_SETTER_FACTORY
});

/**
 * @internal
 */
const _STANDARD_GENERATOR_KEY = 'generator';
/**
 * @internal
 */
const __STANDARD_GENERATOR_METADATA_SETTER_FACTORY = metaService => value => metaService.set(makeStandardMetaDefinition(_STANDARD_GENERATOR_KEY), value === true ? `Angular v${VERSION.full}` : value);
/**
 * Manages the {@link Standard.generator} metadata
 * @public
 */
const STANDARD_GENERATOR_METADATA_PROVIDER = makeStandardMetadataProvider(_STANDARD_GENERATOR_KEY, {
  s: __STANDARD_GENERATOR_METADATA_SETTER_FACTORY
});

/**
 * Manages the {@link Standard.applicationName} metadata
 * @public
 */
const STANDARD_APPLICATION_NAME_METADATA_PROVIDER = makeStandardMetadataProvider(_GLOBAL_APPLICATION_NAME, {
  g: _GLOBAL_APPLICATION_NAME,
  n: 'application-name'
});
const LINK_TAG = 'link';
const REL_ATTR = 'rel';
const CANONICAL_VAL = 'canonical';
const SELECTOR = `${LINK_TAG}[${REL_ATTR}='${CANONICAL_VAL}']`;
/**
 * Manages the {@link Standard.canonicalUrl} metadata
 * @public
 */
const STANDARD_CANONICAL_URL_METADATA_PROVIDER = makeStandardMetadataProvider(_GLOBAL_CANONICAL_URL, {
  g: _GLOBAL_CANONICAL_URL,
  s: (headElementUpsertOrRemove, doc) => value => {
    let linkElement;
    if (value !== null && value !== undefined) {
      linkElement = doc.createElement(LINK_TAG);
      linkElement.setAttribute(REL_ATTR, CANONICAL_VAL);
      linkElement.setAttribute('href', value.toString());
    }
    headElementUpsertOrRemove(SELECTOR, linkElement);
  },
  d: [_HEAD_ELEMENT_UPSERT_OR_REMOVE, DOCUMENT]
});
const ATTRIBUTE_NAME = 'lang';
/**
 * @internal
 */
const __STANDARD_LOCALE_METADATA_SETTER_FACTORY = doc => locale => {
  const htmlElement = doc.documentElement;
  if (locale === null || locale === undefined) {
    htmlElement.removeAttribute(ATTRIBUTE_NAME);
    return;
  }
  htmlElement.setAttribute(ATTRIBUTE_NAME, locale);
};
/**
 * Manages the {@link Standard.locale} metadata
 * @public
 */
const STANDARD_LOCALE_METADATA_PROVIDER = makeStandardMetadataProvider(_GLOBAL_LOCALE, {
  g: _GLOBAL_LOCALE,
  s: __STANDARD_LOCALE_METADATA_SETTER_FACTORY,
  d: [DOCUMENT]
});

/**
 * @internal
 */
const _STANDARD_THEME_COLOR_KEY = 'themeColor';
const META_NAME = 'theme-color';
/**
 * @internal
 */
const __STANDARD_THEME_COLOR_METADATA_SETTER_FACTORY = ngxMetaMetaService => value => {
  const isValueAnArray = isStandardThemeColorArray(value);
  const baseMetaDefinition = makeStandardMetaDefinition(META_NAME);
  if (!value || !isValueAnArray || !value.length) {
    ngxMetaMetaService.set(baseMetaDefinition, isValueAnArray ? undefined : value);
    return;
  }
  for (const {
    media,
    color
  } of value) {
    ngxMetaMetaService.set(makeStandardMetaDefinition(META_NAME, media ? {
      extras: {
        media
      }
    } : {}), color);
  }
};
const isStandardThemeColorArray = value => Array.isArray(value);
/**
 * Manages the {@link Standard.themeColor} metadata
 * @public
 */
const STANDARD_THEME_COLOR_METADATA_PROVIDER = makeStandardMetadataProvider(_STANDARD_THEME_COLOR_KEY, {
  s: __STANDARD_THEME_COLOR_METADATA_SETTER_FACTORY
});

/**
 * Adds {@link https://ngx-meta.dev/built-in-modules/standard/ | standard module}
 * metadata managers
 *
 * For module-based apps, use {@link NgxMetaStandardModule} instead
 *
 * @public
 */
const provideNgxMetaStandard = () => [STANDARD_TITLE_METADATA_PROVIDER, STANDARD_DESCRIPTION_METADATA_PROVIDER, STANDARD_AUTHOR_METADATA_PROVIDER, STANDARD_KEYWORDS_METADATA_PROVIDER, STANDARD_GENERATOR_METADATA_PROVIDER, STANDARD_APPLICATION_NAME_METADATA_PROVIDER, STANDARD_CANONICAL_URL_METADATA_PROVIDER, STANDARD_LOCALE_METADATA_PROVIDER, STANDARD_THEME_COLOR_METADATA_PROVIDER];

/**
 * Adds {@link https://ngx-meta.dev/built-in-modules/standard/ | standard module}
 * metadata managers
 *
 * For standalone apps, use {@link provideNgxMetaStandard} instead
 *
 * @public
 */
class NgxMetaStandardModule {
  static {
    this.ɵfac = function NgxMetaStandardModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaStandardModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMetaStandardModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...provideNgxMetaStandard()]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaStandardModule, [{
    type: NgModule,
    args: [{
      providers: [...provideNgxMetaStandard()]
    }]
  }], null, null);
})();

// Main providers

/**
 * Generated bundle index. Do not edit.
 */

export { NgxMetaStandardModule, STANDARD_APPLICATION_NAME_METADATA_PROVIDER, STANDARD_AUTHOR_METADATA_PROVIDER, STANDARD_CANONICAL_URL_METADATA_PROVIDER, STANDARD_DESCRIPTION_METADATA_PROVIDER, STANDARD_GENERATOR_METADATA_PROVIDER, STANDARD_KEYWORDS_METADATA_PROVIDER, STANDARD_LOCALE_METADATA_PROVIDER, STANDARD_THEME_COLOR_METADATA_PROVIDER, STANDARD_TITLE_METADATA_PROVIDER, _STANDARD_THEME_COLOR_KEY, __STANDARD_GENERATOR_METADATA_SETTER_FACTORY, __STANDARD_KEYWORDS_METADATA_SETTER_FACTORY, __STANDARD_LOCALE_METADATA_SETTER_FACTORY, __STANDARD_THEME_COLOR_METADATA_SETTER_FACTORY, __STANDARD_TITLE_METADATA_SETTER_FACTORY, provideNgxMetaStandard };
