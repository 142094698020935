import { of } from 'rxjs';
import { inject, provideAppInitializer } from '@angular/core';
import { Router } from '@angular/router';
import { GLOBAL_CONFIG } from '@nats/tokens';
import { createRouterRoutes } from './create-routes';

export function provideNatsRoutesInitializer() {
	return provideAppInitializer(() => {
		const router = inject(Router);
		const config = inject(GLOBAL_CONFIG);
		router.resetConfig(createRouterRoutes(config.pages));
		return of(true);
	});
}
