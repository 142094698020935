import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { NatsPageData } from '@nats/models';
import { CONFIG_TOKEN } from '@nats/tokens';
import { catchError, EMPTY, map, Observable, retry } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageResolver {
	private http = inject(HttpClient);
	private config = inject(CONFIG_TOKEN);

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NatsPageData> {
		console.log({ route, state });
		const { params, queryParams } = route;
		return this.getPageData(route.data['slug'], { ...queryParams, ...params }).pipe(
			retry(3), // retry 3 times if there's an error
			map(res => ({ ...res, pageUrl: state.url })),
			catchError(err => {
				alert('Could not load this page!');
				console.error('💀 PageResolver Error: ', err);
				return EMPTY;
			})
		);
	}

	getPageData(slug: string, routeParams: Params): Observable<NatsPageData> {
		let params = new HttpParams().set('slug', slug);

		if (Object.keys(routeParams).length > 0) {
			params = params.set('data_source', JSON.stringify(routeParams));
		}

		const path = `${this.config.natsUrl}/tour_api.php/content/page`;
		return this.http.get<NatsPageData>(path, { params });
	}
}
