import { Component, inject, Injector, OnInit, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe, DOCUMENT, NgComponentOutlet } from '@angular/common';
import { combineLatest, from, map, Observable, takeUntil } from 'rxjs';

import { LAZY_BLOCKS_MAP } from '../blocks/blocks-map';

import {
	BlockConfig,
	EMPTY_PAGE_DESIGN_LAYOUT,
	EMPTY_PAGE_HIDDEN_BLOCKS,
	isModelViewPage,
	isSetViewPage,
	NatsBlock,
	NatsPageData,
} from '@nats/models';
import {
	API_URL,
	BLOCK_DATA,
	CONFIG_TOKEN,
	GLOBAL_CONFIG,
	PAGE_CUSTOM_DATA,
	PAGE_DATA,
	PAGE_DESIGN_LAYOUT,
} from '@nats/tokens';
import { injectCurrentScreenSize } from '@nats/shared/injections';
import { addStyleTagToHead, createPageGridLayoutStyles } from '@nats/shared/functions';
import { UserConsentService } from '../core/user-consent.service';
import { ConsentModalComponent } from '../helpers/consent-modal.component';
import { LoadingSpinner } from './loading-spinner.component';
import { PreviewModeBannerComponent } from '@nats/shared/components';
import { PreviewDataStore } from '@nats/shared/services';
import { injectDestroy } from 'ngxtension/inject-destroy';
import { toSignal } from '@angular/core/rxjs-interop';
import { GlobalMetadata, NgxMetaService } from '@davidlj95/ngx-meta/core';
import { StandardMetadata } from '@davidlj95/ngx-meta/standard';

// Global variable that will be used to
// store the ids of the pages whose styles have already been added to the DOM
const LOADED_PAGE_STYLES = new Set<string>();

@Component({
	selector: 'nats-component-loader',
	template: `
		@if (previewDataStore.isPreviewMode()) {
			@defer (prefetch on immediate) {
				<nats-preview-mode-banner (closed)="previewDataStore.closePreviewMode()" />
			}
		}

		@if (userConsent.showModal()) {
			@defer (when userConsent.showModal()) {
				<nats-consent-modal />
			}
		}

		@if (blocks$ | async; as blocks) {
			<div
				class="components-wrapper {{ pageWidthCssClass() }}"
				[style.filter]="userConsent.showModal() ? 'blur(20px)' : ''">
				<div style="display: grid; grid-template-columns: repeat(12, 1fr)">
					@for (block of blocks; track block.id) {
						<div
							id="block-{{ block.id }}-page-{{ block.pageId }}-layout"
							class="block bid-{{ block.id }}">
							@if (block.component | async; as cmp) {
								<div class="section">
									<ng-container
										[ngComponentOutlet]="cmp"
										[ngComponentOutletInjector]="block.dataInjector" />
								</div>
							} @else {
								<nats-loading-spinner />
							}
						</div>
					}
				</div>
			</div>
		}
	`,
	standalone: true,
	imports: [
		AsyncPipe,
		NgComponentOutlet,
		ConsentModalComponent,
		LoadingSpinner,
		PreviewModeBannerComponent,
	],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ComponentLoader implements OnInit {
	private configData = inject(CONFIG_TOKEN);
	private siteData = inject(GLOBAL_CONFIG);
	private route = inject(ActivatedRoute);
	private injector = inject(Injector);
	private document = inject(DOCUMENT);
	private ngxMetaService = inject(NgxMetaService);

	previewDataStore = inject(PreviewDataStore);

	userConsent = inject(UserConsentService);

	destroy$ = injectDestroy();

	// page data will come from resolver, will be set when we initialize the routes and will be of type RoutePageData
	pageData$: Observable<NatsPageData> = this.route.data.pipe(map(x => x['page']));

	// current screen size based on the media queries
	currentScreenSize$ = injectCurrentScreenSize();

	pageWidthCssClass = toSignal(
		this.pageData$.pipe(
			map(pageData => {
				if (!pageData.settings) return '';
				if (pageData.settings.page_width === 'contained') return 'container';
				if (pageData.settings.page_width === 'full') return 'container-fluid';
				return '';
			})
		)
	);

	blocks$: Observable<BlockConfig[]> = combineLatest([
		this.pageData$,
		this.currentScreenSize$,
	]).pipe(
		map(([pageData, currentScreenSize]) => {
			const blocksData = pageData['blocks'];
			if (!blocksData) return [];

			const designLayout = pageData['settings']?.design_layout;

			const layouts = designLayout?.layouts || EMPTY_PAGE_DESIGN_LAYOUT;
			const pageHiddenBlocks = designLayout?.hidden_blocks || EMPTY_PAGE_HIDDEN_BLOCKS;

			const visiblePageBlocks = blocksData.filter(block => {
				// show only enabled blocks
				if (block.settings.enabled !== 'yes') return false;

				// show only blocks that are not hidden
				if (pageHiddenBlocks[currentScreenSize]?.includes(block.cms_block_id)) return false;

				return true;
			});

			const blocksWithData = visiblePageBlocks.map(block => {
				return {
					id: block.cms_block_id,
					pageId: pageData.cms_page_id,
					dataInjector: this.createInjector(pageData, block, visiblePageBlocks),
					component: createComponentImport(block.settings.type),
				} as BlockConfig;
			});

			const pageGridStyles = createPageGridLayoutStyles(
				layouts,
				blocksWithData,
				pageHiddenBlocks,
				pageData.cms_page_id
			);

			// if the styles for the current page have not been added to the DOM we add them
			if (!LOADED_PAGE_STYLES.has(pageData.cms_page_id)) {
				addStyleTagToHead(pageGridStyles, `page-${pageData.cms_page_id}-grid-styles`);
				LOADED_PAGE_STYLES.add(pageData.cms_page_id);
			}

			return blocksWithData;
		})
	);

	ngOnInit() {
		this.pageData$.pipe(takeUntil(this.destroy$)).subscribe(pageData => {
			const { body_class: pageBodyClass, body_id: pageBodyId } = pageData.settings || {};
			const { body_tag_id: themeBodyId, body_tag_class: themeBodyClass } =
				this.siteData.theme?.settings || {};

			// Page ID overrides Theme ID
			this.document.body.id = pageBodyId || themeBodyId || '';
			// Page Class appends to Theme Class
			this.document.body.className = `${themeBodyClass || ''} ${pageBodyClass || ''}`.trim();

			this.userConsent.showIfNotApproved(pageData);

			this.setSEOMetadata(pageData);
		});
	}

	createInjector(pageData: NatsPageData, blockData: NatsBlock, pageBlocks: NatsBlock[]): Injector {
		return Injector.create({
			parent: this.injector,
			providers: [
				// here we can provide also other data that can be needed by components
				// in order for the components to be aware of the page
				// example: auth data, whole page config, page data
				// tokens for this kind on data should be at shared tokens library
				{ provide: PAGE_DATA, useValue: pageData },
				{ provide: BLOCK_DATA, useValue: blockData },
				{ provide: GLOBAL_CONFIG, useValue: this.siteData },
				{ provide: API_URL, useValue: this.configData.natsUrl },
				{ provide: PAGE_DESIGN_LAYOUT, useValue: pageData['settings']?.design_layout || {} },
				{
					provide: PAGE_CUSTOM_DATA,
					useValue: {
						isModelViewPage: isModelViewPage(pageBlocks),
						isSetViewPage: isSetViewPage(pageBlocks),
					},
				},
			],
		});
	}

	private setSEOMetadata(pageData: NatsPageData) {
		const { meta_title, meta_author, meta_description, meta_image_url, meta_keywords } =
			pageData.settings ?? {};

		this.ngxMetaService.set({
			title: meta_title || pageData.name,
			description: meta_description || undefined,
			standard: {
				keywords: meta_keywords?.split(',') || undefined,
				author: meta_author || undefined,
			},
			image: meta_image_url
				? {
						url: meta_image_url,
						alt: meta_title || pageData.name,
					}
				: undefined,
		} satisfies GlobalMetadata & StandardMetadata);
	}
}

// create component import based on type
export function createComponentImport(type?: string): Observable<Type<unknown>> {
	// if the type is not found in the map we use the not_found component
	const key = type && LAZY_BLOCKS_MAP[type] ? type : 'not_found';
	const importFn = LAZY_BLOCKS_MAP[key];
	return from(importFn());
}
