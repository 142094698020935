import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { NatsPageData } from '@nats/models';
import { CONFIG_TOKEN } from '@nats/tokens';
import { catchError, EMPTY, map, retry } from 'rxjs';
import { withCache } from '@ngneat/cashew';

export const pageResolver: ResolveFn<NatsPageData> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const http = inject(HttpClient);
	const config = inject(CONFIG_TOKEN);

	const routeParams = { ...route.queryParams, ...route.params };

	let httpParams = new HttpParams().set('slug', route.data['slug']);
	if (Object.keys(routeParams).length > 0) {
		httpParams = httpParams.set('data_source', JSON.stringify(routeParams));
	}

	const path = `${config.natsUrl}/tour_api.php/content/page`;
	return http
		.get<NatsPageData>(path, {
			params: httpParams,
			// we cache page data for 1 minute
			context: withCache({ ttl: 60 * 1000 }), // ttl is in milliseconds
		})
		.pipe(
			retry(3), // retry 3 times if there's an error
			map(res => ({ ...res, pageUrl: state.url })),
			catchError(err => {
				alert('Could not load this page!');
				console.error('💀 PageResolver Error: ', err);
				return EMPTY;
			})
		);
};
