import { Type } from '@angular/core';

export const LAZY_BLOCKS_MAP: Record<string, () => Promise<Type<unknown>>> = {
	not_found: () => import('./not-found.component').then(m => m.default),

	navigation: () => import('./navigation/navigation.component').then(m => m.default),
	navigation_list: () => import('./navigation-list/navigation-list.component').then(m => m.default),
	html: () => import('./html-block/html-block.component').then(m => m.default),
	set_list: () => import('./set-list/set-list.component').then(m => m.default),
	model_list: () => import('./model-list/model-list.component').then(m => m.default),
	set_view: () => import('./content-view/content-view.component').then(m => m.default),
	model_view: () => import('./model-view/model-view.component').then(m => m.default),
	comment: () => import('./comments/comments.component').then(m => m.default),
	carousel: () => import('./carousel/carousel.component').then(m => m.default),
	data_type_list: () => import('./data-type-list/data-type-list.component').then(m => m.default),
	image: () => import('./image/image.component').then(m => m.default),
	link: () => import('./link/link.component').then(m => m.default),
	// link: () => import('./search-text/search-text-block.component').then(m => m.default),
	text_search: () => import('./search-text/search-text-block.component').then(m => m.default),
	login: () => import('./login/login.component').then(m => m.default),
};
