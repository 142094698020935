import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { makeComposedKeyValMetaDefinition, makeMetadataManagerProviderFromSetterFactory, NgxMetaMetaService, _GLOBAL_DESCRIPTION, _maybeTooLongDevMessage, _GLOBAL_TITLE, _maybeNonHttpUrlDevMessage, _GLOBAL_IMAGE } from '@davidlj95/ngx-meta/core';
const makeTwitterCardMetaDefinition = (...names) => makeComposedKeyValMetaDefinition(['twitter', ...names]);
const TWITTER_KEY = `twitterCard`;
const TWITTER_KEY_KEBAB_CASE = 'twitter-card';
const makeTwitterCardMetadataProvider = (key, opts = {}) => makeMetadataManagerProviderFromSetterFactory(opts.s ?? (metaService => value => metaService.set(makeTwitterCardMetaDefinition(opts.p ?? key), value)), {
  d: [NgxMetaMetaService],
  jP: [TWITTER_KEY, key],
  g: opts.g,
  m: opts.m
});

/**
 * Manages the {@link TwitterCard.card} metadata
 * @public
 */
const TWITTER_CARD_CARD_METADATA_PROVIDER = makeTwitterCardMetadataProvider('card');
const KEY$1 = 'site';
/**
 * Manages the {@link TwitterCard.site} metadata
 * @public
 */
const TWITTER_CARD_SITE_METADATA_PROVIDER = makeTwitterCardMetadataProvider(KEY$1, {
  s: metaService => value => {
    metaService.set(makeTwitterCardMetaDefinition(KEY$1), value?.username);
    metaService.set(makeTwitterCardMetaDefinition(KEY$1, 'id'), value?.id);
  }
});
const KEY = 'creator';
/**
 * Manages the {@link TwitterCard.creator} metadata
 * @public
 */
const TWITTER_CARD_CREATOR_METADATA_PROVIDER = makeTwitterCardMetadataProvider(KEY, {
  s: metaService => value => {
    metaService.set(makeTwitterCardMetaDefinition(KEY), value?.username);
    metaService.set(makeTwitterCardMetaDefinition(KEY, 'id'), value?.id);
  }
});
const _MODULE_NAME = 'twitter-card';

/**
 * Manages the {@link TwitterCard.description} metadata
 * @public
 */
const TWITTER_CARD_DESCRIPTION_METADATA_PROVIDER = makeTwitterCardMetadataProvider(_GLOBAL_DESCRIPTION, {
  g: _GLOBAL_DESCRIPTION,
  s: metaService => description => {
    /* istanbul ignore next */
    if (ngDevMode) {
      _maybeTooLongDevMessage(description, 200, {
        module: _MODULE_NAME,
        property: _GLOBAL_DESCRIPTION,
        value: description,
        link: 'https://developer.x.com/en/docs/twitter-for-websites/cards/overview/markup#:~:text=n/a-,twitter%3Adescription,-Description%20of%20content'
      });
    }
    metaService.set(makeTwitterCardMetaDefinition(_GLOBAL_DESCRIPTION), description);
  }
});

/**
 * Manages the {@link TwitterCard.title} metadata
 * @public
 */
const TWITTER_CARD_TITLE_METADATA_PROVIDER = makeTwitterCardMetadataProvider(_GLOBAL_TITLE, {
  g: _GLOBAL_TITLE
});

/**
 * @internal
 */
const __TWITTER_CARD_IMAGE_METADATA_SETTER_FACTORY = metaService => image => {
  // Why not an `if`? Checkout https://github.com/davidlj95/ngx/pull/731
  ngDevMode && _maybeNonHttpUrlDevMessage(image?.url, {
    module: _MODULE_NAME,
    property: 'image',
    link: 'https://devcommunity.x.com/t/card-error-unable-to-render-or-no-image-read-this-first/62736'
  });
  metaService.set(makeTwitterCardMetaDefinition(_GLOBAL_IMAGE), image?.url?.toString());
  metaService.set(makeTwitterCardMetaDefinition(_GLOBAL_IMAGE, 'alt'), image?.alt);
};
/**
 * Manages the {@link TwitterCard.image} metadata
 * @public
 */
const TWITTER_CARD_IMAGE_METADATA_PROVIDER = makeTwitterCardMetadataProvider(_GLOBAL_IMAGE, {
  g: _GLOBAL_IMAGE,
  s: __TWITTER_CARD_IMAGE_METADATA_SETTER_FACTORY,
  m: true
});

/**
 * Adds {@link https://ngx-meta.dev/built-in-modules/twitter-cards/ | Twitter Cards module}
 * metadata managers
 *
 * For module-based apps, use {@link NgxMetaTwitterCardModule} instead
 *
 * @public
 */
const provideNgxMetaTwitterCard = () => [TWITTER_CARD_CARD_METADATA_PROVIDER, TWITTER_CARD_SITE_METADATA_PROVIDER, TWITTER_CARD_CREATOR_METADATA_PROVIDER, TWITTER_CARD_DESCRIPTION_METADATA_PROVIDER, TWITTER_CARD_TITLE_METADATA_PROVIDER, TWITTER_CARD_IMAGE_METADATA_PROVIDER];

/**
 * Adds {@link https://ngx-meta.dev/built-in-modules/twitter-cards/ | Twitter Cards module}
 * metadata managers
 *
 * For standalone apps, use {@link provideNgxMetaTwitterCard} instead
 *
 * @public
 */
class NgxMetaTwitterCardModule {
  static {
    this.ɵfac = function NgxMetaTwitterCardModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaTwitterCardModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMetaTwitterCardModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...provideNgxMetaTwitterCard()]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaTwitterCardModule, [{
    type: NgModule,
    args: [{
      providers: [...provideNgxMetaTwitterCard()]
    }]
  }], null, null);
})();

/**
 * See {@link TwitterCardType}
 * @public
 */
const TWITTER_CARD_TYPE_SUMMARY = 'summary';
/**
 * See {@link TwitterCardType}
 * @public
 */
const TWITTER_CARD_TYPE_SUMMARY_LARGE_IMAGE = 'summary_large_image';
/**
 * See {@link TwitterCardType}
 * @public
 */
const TWITTER_CARD_TYPE_APP = 'app';
/**
 * See {@link TwitterCardType}
 * @public
 */
const TWITTER_CARD_TYPE_PLAYER = 'player';

// Main providers

/**
 * Generated bundle index. Do not edit.
 */

export { NgxMetaTwitterCardModule, TWITTER_CARD_CARD_METADATA_PROVIDER, TWITTER_CARD_CREATOR_METADATA_PROVIDER, TWITTER_CARD_DESCRIPTION_METADATA_PROVIDER, TWITTER_CARD_IMAGE_METADATA_PROVIDER, TWITTER_CARD_SITE_METADATA_PROVIDER, TWITTER_CARD_TITLE_METADATA_PROVIDER, TWITTER_CARD_TYPE_APP, TWITTER_CARD_TYPE_PLAYER, TWITTER_CARD_TYPE_SUMMARY, TWITTER_CARD_TYPE_SUMMARY_LARGE_IMAGE, __TWITTER_CARD_IMAGE_METADATA_SETTER_FACTORY, provideNgxMetaTwitterCard };
