import { Route, Routes } from '@angular/router';
import { ComponentLoader } from '../components';
import { PageResolver } from './page.resolver';
import { RoutePageData } from '@nats/models';

const UnauthorisedComponent = () =>
	import('../components/unauthorised.component').then(m => m.UnauthorisedComponent);

export const createRouterRoutes = (routes: RoutePageData[]): Route[] => {
	return [
		{
			path: '',
			children: createChildrenRoutes(routes),
		},
		{
			path: 'unauthorised',
			loadComponent: UnauthorisedComponent,
			title: 'Unauthorised',
		},
		{
			path: '**',
			redirectTo: '',
			pathMatch: 'full',
		},
	] as Routes;
};

const createChildrenRoutes = (routes: RoutePageData[]): Route[] => {
	const routeList: Route[] = routes.map(routeData => createRouteFromRouteData(routeData));

	console.log({ routeList });

	return routeList;
};

const createRouteFromRouteData = (route: RoutePageData): Route => {
	return {
		path: route.slug.slice(1), // remove first "/"
		component: ComponentLoader,
		resolve: { page: PageResolver },
		data: route,
		title: route.name,
	};
};
