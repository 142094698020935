import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'nats-loading-spinner',
	template: `
		<div
			class="d-flex align-items-center justify-content-center"
			[style.min-height.px]="minHeight() || 300">
			<div class="spinner">
				<div class="double-bounce1"></div>
				<div class="double-bounce2"></div>
			</div>
		</div>
	`,
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinner {
	readonly minHeight = input(50);
}
