import { Type } from '@angular/core';

export const LAZY_BLOCKS_MAP: Record<
	string,
	{
		loader: () => Promise<Type<unknown>>;
		// needed for the loading spinner height to not take more space than needed
		minHeight?: number;
	}
> = {
	not_found: {
		loader: () => import('./not-found.component').then(m => m.default),
		minHeight: 300,
	},
	navigation: {
		loader: () => import('./navigation/navigation.component').then(m => m.default),
		minHeight: 60,
	},
	navigation_list: {
		loader: () => import('./navigation-list/navigation-list.component').then(m => m.default),
		minHeight: 300,
	},
	html: {
		loader: () => import('./html-block/html-block.component').then(m => m.default),
		minHeight: 150,
	},
	set_list: {
		loader: () => import('./set-list/set-list.component').then(m => m.default),
		minHeight: 500,
	},
	model_list: {
		loader: () => import('./model-list/model-list.component').then(m => m.default),
		minHeight: 750,
	},
	set_view: {
		loader: () => import('./content-view/content-view.component').then(m => m.default),
		minHeight: 600,
	},
	model_view: {
		loader: () => import('./model-view/model-view.component').then(m => m.default),
		minHeight: 600,
	},
	comment: {
		loader: () => import('./comments/comments.component').then(m => m.default),
		minHeight: 300,
	},
	carousel: {
		loader: () => import('./carousel/carousel.component').then(m => m.default),
		minHeight: 300,
	},
	data_type_list: {
		loader: () => import('./data-type-list/data-type-list.component').then(m => m.default),
		minHeight: 500,
	},
	image: {
		loader: () => import('./image/image.component').then(m => m.default),
		minHeight: 400,
	},
	link: {
		loader: () => import('./link/link.component').then(m => m.default),
		minHeight: 100,
	},
	// link: {
	// loader: () => import('./search-text/search-text-block.component').then(m => m.default),
	// minHeight: 300,
	text_search: {
		loader: () => import('./search-text/search-text-block.component').then(m => m.default),
		minHeight: 40,
	},
	login: {
		loader: () => import('./login/login.component').then(m => m.default),
		minHeight: 300,
	},
};
