import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'nats-loading-spinner',
	template: `
		<div class="d-flex align-items-center justify-content-center" style="min-height: 300px">
			<div class="spinner">
				<div class="double-bounce1"></div>
				<div class="double-bounce2"></div>
			</div>
		</div>
	`,
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinner {}
