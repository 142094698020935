import { HttpInterceptorFn } from '@angular/common/http';

export const contentEncodingInterceptor: HttpInterceptorFn = (request, next) => {
	if (request.method === 'GET') {
		const req = request.clone({
			setHeaders: { 'x-nats-entity-decode': '1' },
		});
		return next(req);
	}
	return next(request);
};
