import { ApplicationRef, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { initializeGoogleAnalytics } from '@nats/shared/functions';
import { filter, take } from 'rxjs';
import { GLOBAL_CONFIG } from '@nats/tokens';
import { LAZY_BLOCKS_MAP } from './blocks/blocks-map';

const isLiveEditing = document.location.href.includes('natsLiveEditor');

@Component({
	selector: 'nats-tours-root',
	template: ` <router-outlet /> `,
	imports: [RouterOutlet],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	constructor() {
		const siteData = inject(GLOBAL_CONFIG);
		const appRef = inject(ApplicationRef);

		if (isLiveEditing) {
			import('@nats/shared/live-editor').then(({ enableLiveEditor }) => {
				enableLiveEditor();
			});
		} else {
			appRef.isStable
				// wait for the app to be stable at least once before initializing GA
				.pipe(filter(Boolean), take(1))
				.subscribe(() => initializeGoogleAnalytics(siteData.area.settings.ga_trackingid));

			preloadBlocks();
		}
	}
}

function preloadBlocks() {
	setTimeout(() => {
		requestIdleCallbackWithFallback(() => {
			Object.values(LAZY_BLOCKS_MAP).forEach(({ loader }) => {
				loader().then();
			});
		});
	}, 10000); // after 10 seconds
}

function requestIdleCallbackWithFallback(callback: () => void) {
	if (typeof window !== 'undefined' && window['requestIdleCallback'] !== undefined) {
		window.requestIdleCallback(callback);
	} else {
		setTimeout(callback, 0);
	}
}
