import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, ENVIRONMENT_INITIALIZER, NgModule } from '@angular/core';
import * as i2 from '@davidlj95/ngx-meta/core';
import { _formatDevMessage, _NgxMetaRouteValuesService } from '@davidlj95/ngx-meta/core';
import * as i1 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
const NGX_META_ROUTE_STRATEGY = new InjectionToken(ngDevMode ? 'NgxMeta Route Strategy' : 'NgxMetaRS');
const _MODULE_NAME = 'routing';

// WTF is this? Why not just import `EventType`? Well, compatibility reasons 🙃
// See https://github.com/davidlj95/ngx/pull/246 for the details
const NAVIGATION_END_EVENT_TYPE = new NavigationEnd(0, '', '').type;
class NgxMetaRouterListenerService {
  constructor(router, activatedRoute, strategy, ngxMetaService, routeMetadataValues) {
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.strategy = strategy;
    this.ngxMetaService = ngxMetaService;
    this.routeMetadataValues = routeMetadataValues;
  }
  listen() {
    if (this.sub) {
      if (ngDevMode) {
        console.warn(_formatDevMessage(['prevented listening for route changes twice', 'Ensure routing provider or module is only imported once'].join('\n'), {
          module: _MODULE_NAME
        }));
      }
      return;
    }
    this.sub = this.router.events.pipe(filter(({
      type
    }) => type === NAVIGATION_END_EVENT_TYPE)).subscribe({
      next: () => {
        if (!this.strategy) {
          if (ngDevMode) {
            console.warn(_formatDevMessage(['tried to set metadata for this route, but no metadata route strategy was found', 'Provide at least one `MetadataRouteStrategy` to resolve metadata for a route'].join('\n'), {
              module: _MODULE_NAME,
              value: this.router.url
            }));
          }
          return;
        }
        const values = this.strategy(this.activatedRoute.snapshot);
        this.ngxMetaService.set(values);
        this.routeMetadataValues.set(values);
      }
    });
  }
  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
  static {
    this.ɵfac = function NgxMetaRouterListenerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaRouterListenerService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(NGX_META_ROUTE_STRATEGY, 8), i0.ɵɵinject(i2.NgxMetaService), i0.ɵɵinject(i2._NgxMetaRouteValuesService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxMetaRouterListenerService,
      factory: NgxMetaRouterListenerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaRouterListenerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Router
  }, {
    type: i1.ActivatedRoute
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [NGX_META_ROUTE_STRATEGY]
    }]
  }, {
    type: i2.NgxMetaService
  }, {
    type: i2._NgxMetaRouteValuesService
  }], null);
})();
const CURRENT_ROUTE_DATA_ROUTE_STRATEGY = activatedRouteSnapshot => {
  let currentRouteSnapshot = activatedRouteSnapshot;
  while (currentRouteSnapshot.firstChild != null) {
    currentRouteSnapshot = currentRouteSnapshot.firstChild;
  }
  return currentRouteSnapshot.data[ROUTING_KEY];
};
const ROUTING_KEY = 'meta';
const ROUTING_INITIALIZER = {
  provide: ENVIRONMENT_INITIALIZER,
  multi: true,
  useFactory: routerListener => {
    return () => {
      routerListener.listen();
    };
  },
  deps: [NgxMetaRouterListenerService]
};
const DEFAULT_METADATA_ROUTE_STRATEGY = {
  provide: NGX_META_ROUTE_STRATEGY,
  useValue: CURRENT_ROUTE_DATA_ROUTE_STRATEGY
};
const ROUTING_PROVIDERS = [DEFAULT_METADATA_ROUTE_STRATEGY, ROUTING_INITIALIZER, _NgxMetaRouteValuesService];

/**
 * Allows to manage metadata of a page by specifying it in its Angular's
 * {@link https://angular.dev/api/router/Route#:~:text=a%20synchronous%20context.-,data,-Data | Route.data}
 *
 * For standalone apps, use {@link provideNgxMetaRouting} instead
 *
 * @public
 */
class NgxMetaRoutingModule {
  static forRoot() {
    return {
      ngModule: NgxMetaRoutingModule,
      providers: ROUTING_PROVIDERS
    };
  }
  static {
    this.ɵfac = function NgxMetaRoutingModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaRoutingModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMetaRoutingModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaRoutingModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Allows to manage metadata of a page by specifying it in its Angular's
 * {@link https://angular.dev/api/router/Route#:~:text=a%20synchronous%20context.-,data,-Data | Route.data}
 *
 * For module-based apps, use {@link NgxMetaRoutingModule} instead
 *
 * @public
 */
const provideNgxMetaRouting = () => ROUTING_PROVIDERS;

// Main providers

/**
 * Generated bundle index. Do not edit.
 */

export { NgxMetaRoutingModule, provideNgxMetaRouting };
