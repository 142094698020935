import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { makeComposedKeyValMetaDefinition, _KEY_ATTRIBUTE_PROPERTY, makeMetadataManagerProviderFromSetterFactory, NgxMetaMetaService, _GLOBAL_TITLE, _maybeNonHttpUrlDevMessage, _GLOBAL_IMAGE, _GLOBAL_CANONICAL_URL, _GLOBAL_DESCRIPTION, _maybeTooLongDevMessage, _GLOBAL_LOCALE, _GLOBAL_APPLICATION_NAME } from '@davidlj95/ngx-meta/core';
const makeOpenGraphMetaDefinition = (...names) => makeComposedKeyValMetaDefinition(['og', ...names], {
  keyAttr: _KEY_ATTRIBUTE_PROPERTY
});
const OPEN_GRAPH_KEY = 'openGraph';
const makeOpenGraphMetadataProvider = (key, opts = {}) => makeMetadataManagerProviderFromSetterFactory(opts.s ?? (metaService => value => metaService.set(makeOpenGraphMetaDefinition(opts.p ?? key), value)), {
  d: [NgxMetaMetaService],
  jP: [OPEN_GRAPH_KEY, key],
  g: opts.g,
  m: opts.m
});

/**
 * Manages the {@link OpenGraph.title} metadata
 * @public
 */
const OPEN_GRAPH_TITLE_METADATA_PROVIDER = makeOpenGraphMetadataProvider(_GLOBAL_TITLE, {
  g: _GLOBAL_TITLE
});

/**
 * Manages the {@link OpenGraph."type"} metadata
 * @public
 */
const OPEN_GRAPH_TYPE_METADATA_PROVIDER = makeOpenGraphMetadataProvider('type');
const _MODULE_NAME = 'open-graph';
const NO_KEY_VALUE = {
  url: undefined,
  alt: undefined,
  secureUrl: null,
  type: null,
  width: null,
  height: null
};
/**
 * @internal
 */
const __OPEN_GRAPH_IMAGE_SETTER_FACTORY = metaService => value => {
  const imageUrl = value?.url?.toString();
  const effectiveValue = imageUrl !== undefined && imageUrl !== null ? value : NO_KEY_VALUE;
  // Why not an `if`? Checkout https://github.com/davidlj95/ngx/pull/731
  ngDevMode && _maybeNonHttpUrlDevMessage(imageUrl, {
    module: _MODULE_NAME,
    property: _GLOBAL_IMAGE,
    link: 'https://stackoverflow.com/a/9858694/3263250'
  });
  metaService.set(makeOpenGraphMetaDefinition(_GLOBAL_IMAGE), imageUrl);
  metaService.set(makeOpenGraphMetaDefinition(_GLOBAL_IMAGE, 'alt'), effectiveValue?.alt);
  metaService.set(makeOpenGraphMetaDefinition(_GLOBAL_IMAGE, 'secure_url'), effectiveValue?.secureUrl?.toString());
  metaService.set(makeOpenGraphMetaDefinition(_GLOBAL_IMAGE, 'type'), effectiveValue?.type);
  metaService.set(makeOpenGraphMetaDefinition(_GLOBAL_IMAGE, 'width'), effectiveValue?.width?.toString());
  metaService.set(makeOpenGraphMetaDefinition(_GLOBAL_IMAGE, 'height'), effectiveValue?.height?.toString());
};
/**
 * Manages the {@link OpenGraph.image} metadata
 * @public
 */
const OPEN_GRAPH_IMAGE_METADATA_PROVIDER = makeOpenGraphMetadataProvider(_GLOBAL_IMAGE, {
  s: __OPEN_GRAPH_IMAGE_SETTER_FACTORY,
  g: _GLOBAL_IMAGE,
  m: true
});

/**
 * Manages the {@link OpenGraph.url} metadata
 * @public
 */
const OPEN_GRAPH_URL_METADATA_PROVIDER = makeOpenGraphMetadataProvider('url', {
  g: _GLOBAL_CANONICAL_URL
});

/**
 * Manages the {@link OpenGraph.description} metadata
 * @public
 */
const OPEN_GRAPH_DESCRIPTION_METADATA_PROVIDER = makeOpenGraphMetadataProvider(_GLOBAL_DESCRIPTION, {
  g: _GLOBAL_DESCRIPTION,
  s: metaService => description => {
    /* istanbul ignore next */
    if (ngDevMode) {
      _maybeTooLongDevMessage(description, 300, {
        module: _MODULE_NAME,
        property: _GLOBAL_DESCRIPTION,
        value: description,
        link: 'https://stackoverflow.com/q/8914476/3263250'
      });
    }
    metaService.set(makeOpenGraphMetaDefinition(_GLOBAL_DESCRIPTION), description);
  }
});

/**
 * Manages the {@link OpenGraph.locale} metadata
 * @public
 */
const OPEN_GRAPH_LOCALE_METADATA_PROVIDER = makeOpenGraphMetadataProvider(_GLOBAL_LOCALE, {
  g: _GLOBAL_LOCALE
});

/**
 * Manages the {@link OpenGraph.siteName} metadata
 * @public
 */
const OPEN_GRAPH_SITE_NAME_METADATA_PROVIDER = makeOpenGraphMetadataProvider('siteName', {
  g: _GLOBAL_APPLICATION_NAME,
  p: 'site_name'
});

/**
 * Adds {@link https://ngx-meta.dev/built-in-modules/open-graph/ | Open Graph module}
 * basic & optional metadata managers
 *
 * For module-based apps, use {@link NgxMetaOpenGraphModule} instead
 *
 * @public
 */
const provideNgxMetaOpenGraph = () => [OPEN_GRAPH_TITLE_METADATA_PROVIDER, OPEN_GRAPH_TYPE_METADATA_PROVIDER, OPEN_GRAPH_IMAGE_METADATA_PROVIDER, OPEN_GRAPH_URL_METADATA_PROVIDER, OPEN_GRAPH_DESCRIPTION_METADATA_PROVIDER, OPEN_GRAPH_LOCALE_METADATA_PROVIDER, OPEN_GRAPH_SITE_NAME_METADATA_PROVIDER];

/**
 * Adds {@link https://ngx-meta.dev/built-in-modules/open-graph/ | Open Graph module}
 * basic & optional metadata managers
 *
 * For standalone apps, use {@link provideNgxMetaOpenGraph} instead
 *
 * @public
 */
class NgxMetaOpenGraphModule {
  static {
    this.ɵfac = function NgxMetaOpenGraphModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaOpenGraphModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMetaOpenGraphModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...provideNgxMetaOpenGraph()]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaOpenGraphModule, [{
    type: NgModule,
    args: [{
      providers: [...provideNgxMetaOpenGraph()]
    }]
  }], null, null);
})();
const OPEN_GRAPH_PROFILE_KEY = 'profile';
const makeOpenGraphProfileMetadataProvider = (key, opts = {}) => makeMetadataManagerProviderFromSetterFactory(metaService => value => metaService.set(makeOpenGraphMetaDefinition(...[OPEN_GRAPH_PROFILE_KEY, opts.p ?? key]), value), {
  d: [NgxMetaMetaService],
  jP: [OPEN_GRAPH_KEY, OPEN_GRAPH_PROFILE_KEY, key]
});

/**
 * Manages the {@link OpenGraphProfile.firstName} metadata
 * @public
 */
const OPEN_GRAPH_PROFILE_FIRST_NAME_METADATA_PROVIDER = makeOpenGraphProfileMetadataProvider('firstName', {
  p: 'first_name'
});

/**
 * Manages the {@link OpenGraphProfile.lastName} metadata
 * @public
 */
const OPEN_GRAPH_PROFILE_LAST_NAME_METADATA_PROVIDER = makeOpenGraphProfileMetadataProvider('lastName', {
  p: 'last_name'
});

/**
 * Manages the {@link OpenGraphProfile.username} metadata
 * @public
 */
const OPEN_GRAPH_PROFILE_USERNAME_METADATA_PROVIDER = makeOpenGraphProfileMetadataProvider('username');

/**
 * Manages the {@link OpenGraphProfile.gender} metadata
 * @public
 */
const OPEN_GRAPH_PROFILE_GENDER_METADATA_PROVIDER = makeOpenGraphProfileMetadataProvider('gender');

/**
 * Adds {@link https://ngx-meta.dev/built-in-modules/open-graph/ | Open Graph module}
 * profile metadata managers
 *
 * For module-based apps, use {@link NgxMetaOpenGraphProfileModule} instead
 *
 * @public
 */
const provideNgxMetaOpenGraphProfile = () => [OPEN_GRAPH_PROFILE_FIRST_NAME_METADATA_PROVIDER, OPEN_GRAPH_PROFILE_LAST_NAME_METADATA_PROVIDER, OPEN_GRAPH_PROFILE_USERNAME_METADATA_PROVIDER, OPEN_GRAPH_PROFILE_GENDER_METADATA_PROVIDER];

/**
 * Adds {@link https://ngx-meta.dev/built-in-modules/open-graph/ | Open Graph module}
 * profile metadata managers
 *
 * For standalone apps, use {@link provideNgxMetaOpenGraphProfile} instead
 *
 * @public
 */
class NgxMetaOpenGraphProfileModule {
  static {
    this.ɵfac = function NgxMetaOpenGraphProfileModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaOpenGraphProfileModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMetaOpenGraphProfileModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...provideNgxMetaOpenGraphProfile()]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaOpenGraphProfileModule, [{
    type: NgModule,
    args: [{
      providers: [...provideNgxMetaOpenGraphProfile()]
    }]
  }], null, null);
})();

/**
 * See {@link OpenGraphProfileGender}
 * @public
 */
const OPEN_GRAPH_PROFILE_GENDER_FEMALE = 'female';
/**
 * See {@link OpenGraphProfileGender}
 * @public
 */
const OPEN_GRAPH_PROFILE_GENDER_MALE = 'male';

/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_MUSIC_SONG = 'music.song';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_MUSIC_ALBUM = 'music.album';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_MUSIC_PLAYLIST = 'music.playlist';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_MUSIC_RADIO_STATION = 'music.radio_station';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_VIDEO_MOVIE = 'video.movie';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_VIDEO_EPISODE = 'video.episode';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_VIDEO_TV_SHOW = 'video.tv_show';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_VIDEO_OTHER = 'video.other';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_ARTICLE = 'article';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_BOOK = 'book';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_PROFILE = 'profile';
/**
 * See {@link OpenGraphType}
 * @public
 */
const OPEN_GRAPH_TYPE_WEBSITE = 'website';

// Main providers

/**
 * Generated bundle index. Do not edit.
 */

export { NgxMetaOpenGraphModule, NgxMetaOpenGraphProfileModule, OPEN_GRAPH_DESCRIPTION_METADATA_PROVIDER, OPEN_GRAPH_IMAGE_METADATA_PROVIDER, OPEN_GRAPH_LOCALE_METADATA_PROVIDER, OPEN_GRAPH_PROFILE_FIRST_NAME_METADATA_PROVIDER, OPEN_GRAPH_PROFILE_GENDER_FEMALE, OPEN_GRAPH_PROFILE_GENDER_MALE, OPEN_GRAPH_PROFILE_GENDER_METADATA_PROVIDER, OPEN_GRAPH_PROFILE_LAST_NAME_METADATA_PROVIDER, OPEN_GRAPH_PROFILE_USERNAME_METADATA_PROVIDER, OPEN_GRAPH_SITE_NAME_METADATA_PROVIDER, OPEN_GRAPH_TITLE_METADATA_PROVIDER, OPEN_GRAPH_TYPE_ARTICLE, OPEN_GRAPH_TYPE_BOOK, OPEN_GRAPH_TYPE_METADATA_PROVIDER, OPEN_GRAPH_TYPE_MUSIC_ALBUM, OPEN_GRAPH_TYPE_MUSIC_PLAYLIST, OPEN_GRAPH_TYPE_MUSIC_RADIO_STATION, OPEN_GRAPH_TYPE_MUSIC_SONG, OPEN_GRAPH_TYPE_PROFILE, OPEN_GRAPH_TYPE_VIDEO_EPISODE, OPEN_GRAPH_TYPE_VIDEO_MOVIE, OPEN_GRAPH_TYPE_VIDEO_OTHER, OPEN_GRAPH_TYPE_VIDEO_TV_SHOW, OPEN_GRAPH_TYPE_WEBSITE, OPEN_GRAPH_URL_METADATA_PROVIDER, __OPEN_GRAPH_IMAGE_SETTER_FACTORY, provideNgxMetaOpenGraph, provideNgxMetaOpenGraphProfile };
