import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { CONFIG_TOKEN, NATS_CODE } from '@nats/tokens';
import { PreviewDataStore } from '@nats/shared/services';

export const headersInterceptor: HttpInterceptorFn = (req, next) => {
	const config = inject(CONFIG_TOKEN);
	const natsCode = inject(NATS_CODE);
	const previewDataStore = inject(PreviewDataStore);

	// if the url is login.php, don't add the headers
	if (req.url.includes('login.php')) return next(req);

	const { isPreviewMode, previewData } = previewDataStore;

	const headers: Record<string, string> = { 'X-NATS-cms-area-id': config.cms_area_id };

	if (natsCode) headers['x-nats-natscode'] = natsCode;

	if (isPreviewMode()) {
		const { themeId, secretCode } = previewData();
		headers['x-nats-cms-area-preview'] = secretCode!;
		headers['x-nats-cms-area-preview-theme'] = themeId!;
	}

	const newReq = req.clone({ setHeaders: headers });

	return next(newReq);
};
