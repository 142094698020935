import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, makeEnvironmentProviders, NgModule, SkipSelf, ENVIRONMENT_INITIALIZER } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as i1 from '@angular/router';
import * as i1$1 from '@angular/platform-browser';
const DEFAULTS_TOKEN = new InjectionToken(ngDevMode ? 'NgxMeta Metadata defaults' : 'NgxMetaDefs');

/**
 * @internal
 */
const __HEAD_ELEMENT_UPSERT_OR_REMOVE_FACTORY = doc => (selector, element) => {
  const existingScriptElement = doc.head.querySelector(selector);
  if (existingScriptElement) {
    doc.head.removeChild(existingScriptElement);
  }
  if (element === null || element === undefined) {
    return;
  }
  doc.head.appendChild(element);
};
/**
 * @internal
 */
const _HEAD_ELEMENT_UPSERT_OR_REMOVE = new InjectionToken(ngDevMode ? 'NgxMeta head element upsert or remove util' : 'NgxMetaHEUOR');
/**
 * @internal
 */
const __HEAD_ELEMENT_UPSERT_OR_REMOVE_PROVIDER = {
  provide: _HEAD_ELEMENT_UPSERT_OR_REMOVE,
  useFactory: __HEAD_ELEMENT_UPSERT_OR_REMOVE_FACTORY,
  deps: [DOCUMENT]
};

/**
 * @internal
 */
class _NgxMetaRouteValuesService {
  constructor(router) {
    this.router = router;
    this.values = {};
  }
  get() {
    if (this.router.url != this.url) {
      return {};
    }
    return this.values;
  }
  set(values) {
    if (values === undefined) {
      return;
    }
    this.url = this.router.url;
    this.values = values;
  }
  static {
    this.ɵfac = function _NgxMetaRouteValuesService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || _NgxMetaRouteValuesService)(i0.ɵɵinject(i1.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: _NgxMetaRouteValuesService,
      factory: _NgxMetaRouteValuesService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(_NgxMetaRouteValuesService, [{
    type: Injectable
  }], () => [{
    type: i1.Router
  }], null);
})();

// https://stackoverflow.com/a/8511350/3263250
const isObject = object => object !== null && typeof object === 'object' && !Array.isArray(object);
const METADATA_JSON_RESOLVER = new InjectionToken(ngDevMode ? 'NgxMeta JSON Resolver' : 'NgxMetaJR', {
  providedIn: 'root',
  factory: () => (values, resolverOptions) => {
    if (values === undefined) {
      return;
    }
    const keys = [...resolverOptions.jsonPath];
    let value = values;
    for (const key of keys) {
      if (value === undefined || value === null) {
        break;
      }
      value = value[key];
    }
    const globalValue = resolverOptions.global !== undefined ? values[resolverOptions.global] : undefined;
    if (isObject(value) && isObject(globalValue) && resolverOptions.objectMerge) {
      return {
        ...globalValue,
        ...value
      };
    }
    if (value !== undefined) {
      return value;
    }
    return globalValue;
  }
});
const METADATA_RESOLVER = new InjectionToken(ngDevMode ? 'NgxMeta Metadata Resolver' : 'NgxMetaMR');
const METADATA_RESOLVER_FACTORY = (jsonResolver, routeMetadataValues, defaults) => (values, resolverOptions) => {
  const value = jsonResolver(values, resolverOptions);
  const routeValue = jsonResolver(routeMetadataValues?.get(), resolverOptions);
  const defaultValue = jsonResolver(defaults ?? undefined, resolverOptions);
  if (isObject(value) && (isObject(routeValue) || isObject(defaultValue)) && resolverOptions.objectMerge) {
    return {
      ...defaultValue,
      ...routeValue,
      ...value
    };
  }
  return [value, routeValue, defaultValue].find(v => v !== undefined);
};
const METADATA_RESOLVER_PROVIDER = {
  provide: METADATA_RESOLVER,
  useFactory: METADATA_RESOLVER_FACTORY,
  deps: [METADATA_JSON_RESOLVER, [_NgxMetaRouteValuesService, new Optional()], [DEFAULTS_TOKEN, new Optional()]]
};

/**
 * Abstract class every metadata manager must implement.
 *
 * Used as {@link https://angular.dev/guide/di/dependency-injection-providers#using-an-injectiontoken-object | injection token}
 * to provide metadata managers the library will take into account.
 *
 * Can be created with {@link makeMetadataManagerProviderFromSetterFactory}
 *
 * @public
 */
class NgxMetaMetadataManager {}
/**
 * @internal
 */
const _makeMetadataManager = (id, resolverOptions, set) => ({
  id,
  resolverOptions,
  set
});
/**
 * @internal
 */
const _makeMetadataResolverOptions = (jsonPath, global, objectMerge) => ({
  jsonPath,
  global,
  objectMerge
});

/**
 * @internal
 */
class MetadataRegistry {
  constructor(managers) {
    this.byId = new Map();
    managers?.forEach(manager => this.register(manager));
  }
  register(manager) {
    if (this.byId.has(manager.id)) {
      return;
    }
    this.byId.set(manager.id, manager);
  }
  getAll() {
    return this.byId.values();
  }
  findByGlobalOrJsonPath(globalOrJsonPath) {
    return [...this.getAll()].filter(manager => manager.resolverOptions.global == globalOrJsonPath || manager.resolverOptions.jsonPath.join('.') == globalOrJsonPath);
  }
  static {
    this.ɵfac = function MetadataRegistry_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MetadataRegistry)(i0.ɵɵinject(NgxMetaMetadataManager, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MetadataRegistry,
      factory: MetadataRegistry.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MetadataRegistry, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [NgxMetaMetadataManager]
    }]
  }], null);
})();
const CORE_PROVIDERS = [__HEAD_ELEMENT_UPSERT_OR_REMOVE_PROVIDER, METADATA_RESOLVER_PROVIDER, MetadataRegistry];

/**
 * Adds core services of the library to the application.
 *
 * For module-based apps, use {@link NgxMetaCoreModule.forRoot} instead
 *
 * Allows specifying some default metadata values. Keep reading.
 *
 * @example
 * To specify some default metadata values, use {@link withNgxMetaDefaults}
 *
 * ```typescript
 * provideNgxMetaCore(
 *   withNgxMetaDefaults({title: 'Default title'})
 * )
 * ```
 *
 * @param features - Features to configure the core with. Currently just {@link withNgxMetaDefaults} available
 *
 * @public
 */
const provideNgxMetaCore = (...features) => makeEnvironmentProviders([...CORE_PROVIDERS, ...features.map(feature => feature._providers)]);
const coreFeature = (kind, providers) => ({
  _kind: kind,
  _providers: providers
});
/**
 * Allows to configure default metadata values.
 *
 * Use it as part of {@link provideNgxMetaCore}
 *
 * For module-based apps, check out {@link NgxMetaCoreModule.forRoot}
 *
 * @param defaults - Default metadata values to use
 *
 * @public
 */
const withNgxMetaDefaults = defaults => coreFeature(0 /* CoreFeatureKind.Defaults */, [{
  provide: DEFAULTS_TOKEN,
  useValue: defaults
}]);

/**
 * Adds core providers of `ngx-meta` to the application.
 * Must use {@link NgxMetaCoreModule.forRoot} method.
 *
 * For standalone apps, use {@link provideNgxMetaCore} instead
 *
 * @public
 */
class NgxMetaCoreModule {
  /**
   * Provides the core library services
   *
   * Allows specifying some default metadata values
   *
   * @example
   *
   * You can set some defaults using the `options` argument
   * ```typescript
   * NgxMetaCoreModule.forRoot({defaults: {title: 'Default title'}})
   * ```
   *
   * @param options - Allows providing some default metadata values using `defaults`
   */
  static forRoot(options = {}) {
    return {
      ngModule: NgxMetaCoreModule,
      providers: [...CORE_PROVIDERS, ...(options.defaults !== undefined ? withNgxMetaDefaults(options.defaults)._providers : [])]
    };
  }
  static {
    this.ɵfac = function NgxMetaCoreModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaCoreModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMetaCoreModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaCoreModule, [{
    type: NgModule
  }], null, null);
})();
const METADATA_LOADER = new InjectionToken(ngDevMode ? /* istanbul ignore next */'NgxMeta loader' : 'NgxMetaL');
const METADATA_LOADER_FACTORY = (globalRegistry, localRegistry) => () => {
  const localMetadata = localRegistry.getAll();
  for (const metadata of localMetadata) {
    globalRegistry.register(metadata);
  }
};
const METADATA_LOADER_PROVIDER = {
  provide: METADATA_LOADER,
  useFactory: METADATA_LOADER_FACTORY,
  deps: [[MetadataRegistry, new SkipSelf()], [MetadataRegistry]]
};
const METADATA_LOADER_PROVIDERS = [MetadataRegistry, METADATA_LOADER_PROVIDER, {
  provide: ENVIRONMENT_INITIALIZER,
  multi: true,
  useFactory: loader => () => loader(),
  deps: [METADATA_LOADER]
}];

/**
 * Allows to load metadata modules after library has been initialized
 *
 * For standalone apps, use {@link provideNgxMetaMetadataLoader} instead
 *
 * @public
 */
class NgxMetaMetadataLoaderModule {
  static {
    this.ɵfac = function NgxMetaMetadataLoaderModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaMetadataLoaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMetaMetadataLoaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...METADATA_LOADER_PROVIDERS]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaMetadataLoaderModule, [{
    type: NgModule,
    args: [{
      providers: [...METADATA_LOADER_PROVIDERS]
    }]
  }], null, null);
})();

/**
 * Allows to load metadata modules after library has been initialized
 *
 * For module-based apps, use {@link NgxMetaMetadataLoaderModule} instead
 *
 * @public
 */
const provideNgxMetaMetadataLoader = () => METADATA_LOADER_PROVIDERS;

/**
 * @internal
 */
const _GLOBAL_TITLE = 'title';
/**
 * @internal
 */
const _GLOBAL_DESCRIPTION = 'description';
/**
 * @internal
 */
const _GLOBAL_APPLICATION_NAME = 'applicationName';
/**
 * @internal
 */
const _GLOBAL_CANONICAL_URL = 'canonicalUrl';
/**
 * @internal
 */
const _GLOBAL_LOCALE = 'locale';
/**
 * @internal
 */
const _GLOBAL_IMAGE = 'image';

/**
 * Creates a {@link NgxMetaMetaDefinition} for its use with {@link NgxMetaMetaService}
 * by understanding `<meta>` elements as key / value pair elements.
 * Read the API reference docs for more info.
 *
 * @remarks
 * One can think about some `<meta>` elements as key / value pairs.
 * For instance `<meta name='description' content='Lorem ipsum'>` would
 * actually be a key / pair meta where
 *  - `description` is the key
 *  - `Lorem ipsum` is the value
 *  - `name` is the key's HTML attribute
 *  - `content`is the value's HTML attribute
 *
 * Value is set by {@link NgxMetaMetaService.set} by providing this model and an
 * actual value
 *
 * @param keyName - Name of the key in the key/value meta definition
 * @param options - Specifies HTML attribute defining key, HTML attribute defining
 *               value and optional extras to include in definition
 *               `keyAttr` defaults to `name`
 *               `valAttr` defaults to `content`
 *               `extras` defaults to nothing
 *
 * @public
 */
const makeKeyValMetaDefinition = (keyName, options = {}) => {
  const keyAttr = options.keyAttr ?? _KEY_ATTRIBUTE_NAME;
  const valAttr = options.valAttr ?? _VAL_ATTRIBUTE_CONTENT;
  return {
    withContent: value => ({
      [keyAttr]: keyName,
      [valAttr]: value,
      ...options.extras
    }),
    attrSelector: `${keyAttr}='${keyName}'`
  };
};
/**
 * @internal
 */
const _KEY_ATTRIBUTE_NAME = 'name';
/**
 * @internal
 */
const _KEY_ATTRIBUTE_PROPERTY = 'property';
/**
 * @internal
 */
const _VAL_ATTRIBUTE_CONTENT = 'content';

/**
 * Creates a key / value meta definition (see {@link makeKeyValMetaDefinition})
 * where the key is composed by several parts.
 *
 * @example
 * For instance, Open Graph's meta definition for property `og:title` (hence
 * element `<meta property='og:title'>`) could be created with:
 *
 * ```typescript
 * const ogTitleMetaDefinition = makeComposedKeyValMetaDefinition(
 *   ['og', 'title'],
 *   {
 *     keyAttr: 'property',
 *     separator: ':', // could be omitted, as it's the default one
 *   }
 * )
 * ```
 *
 * @param names - Names to create they key name
 * @param options - Options to create the key/val meta definition.
 *                  See {@link makeKeyValMetaDefinition} options.
 *                  Accepts a `separator` argument, which defines how key names
 *                  will be joined together. Separator defaults to `:`
 * @public
 */
const makeComposedKeyValMetaDefinition = (names, options = {}) => makeKeyValMetaDefinition(names.join(options.separator ?? _COMPOSED_KEY_VAL_META_DEFINITION_DEFAULT_SEPARATOR), {
  ...options
});
/**
 * @internal
 */
const _COMPOSED_KEY_VAL_META_DEFINITION_DEFAULT_SEPARATOR = ':';

/**
 * Creates an Angular's {@link https://angular.dev/guide/di/dependency-injection-providers#factory-providers-usefactory | Factory provider}
 * that provides an {@link NgxMetaMetadataManager}
 *
 * @remarks
 *
 * Factory providers are used for built-in modules instead of Angular services.
 * Reason is that code created by `@Injectable` decorator takes many bytes,
 * whereas a call to this function creating a factory provider takes few.
 *
 * See {@link https://github.com/davidlj95/ngx/issues/112}
 *
 * @param setterFactory - Function that creates a {@link NgxMetaMetadataManager} given some dependencies. See {@link MetadataSetterFactory}
 * @param opts - Options to create the factory.
 *               `d` is the list of dependencies to inject. Defaults to no dependencies
 *               `id` is the {@link NgxMetaMetadataManager.id} to use.
 *               Defaults to resolver options `jsonPath` joined by dots.
 *               `jP` is the `jsonPath` that will be used for the {@link MetadataResolverOptions.jsonPath}
 *               `g` is the `global` that will be used for the {@link MetadataResolverOptions.global}
 *               `m` is the `objectMerge` that will be used for the {@link MetadataResolverOptions.objectMerge}
 *
 * @public
 */
const makeMetadataManagerProviderFromSetterFactory = (setterFactory, opts) => {
  /* istanbul ignore next */
  const deps = opts.d ?? [];
  return {
    provide: NgxMetaMetadataManager,
    multi: true,
    useFactory: (...deps) => _makeMetadataManager(opts.id ?? opts.jP.join('.'), _makeMetadataResolverOptions(opts.jP, opts.g, opts.m), setterFactory(...deps)),
    deps
  };
};

/**
 * Upserts (or removes) `<meta>` elements in the current page using Angular's
 * {@link https://angular.dev/api/platform-browser/Meta | Meta} under the hood.
 *
 * @public
 */
class NgxMetaMetaService {
  constructor(meta) {
    this.meta = meta;
  }
  /**
   * Upserts a specific `<meta>` element, defined by a {@link NgxMetaMetaDefinition}
   * to the given content. If `content` is `null` or `undefined`, removes the
   * `<meta>` element from the page.
   *
   * @param definition - `<meta>` element to upsert or remove
   * @param content - Content to set for the `<meta>` element. Or `null` or `undefined` to remove it from the page.
   */
  set(definition, content) {
    switch (content) {
      case undefined:
      case null:
        this.meta.removeTag(definition.attrSelector);
        return;
      default:
        this.meta.updateTag(definition.withContent(content));
    }
  }
  static {
    this.ɵfac = function NgxMetaMetaService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaMetaService)(i0.ɵɵinject(i1$1.Meta));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxMetaMetaService,
      factory: NgxMetaMetaService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaMetaService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.Meta
  }], null);
})();

/**
 * @internal
 */
const _formatDevMessage = (message, options) => {
  const header = [`ngx-meta/${options.module}:`, options.property, message].filter(s => !!s).join(' ');
  const body = options.value ? `-> Value: "${options.value}"` : undefined;
  const footer = options.link ? `For more information, see ${options.link}` : undefined;
  return [header, body, footer].filter(s => !!s).join('\n');
};
const _MODULE_NAME = 'core';

/**
 * Manages the metadata values of the current page
 *
 * @public
 */
class NgxMetaService {
  constructor(registry, resolver) {
    this.registry = registry;
    this.resolver = resolver;
  }
  /**
   * Sets the metadata values of the current page
   *
   * @remarks
   *
   * The method is designed as an atomic operation. Subsequent calls to this
   * method won't set more metadata, but will instead set the metadata values
   * provided when calling it.
   *
   * For instance,
   *
   * ```typescript
   * ngxMetaService.set({description: 'Description'})
   * ngxMetaService.set({title: 'Title'})
   * ```
   *
   * Will result in a page with title <b>but no description</b>
   *
   * For more information check the {@link https://ngx-meta.dev/guides/set-metadata-using-service/ | service guide docs}
   *
   * @param values - Metadata values to set, as a JSON object
   */
  set(values = {}) {
    const allMetadata = this.registry.getAll();
    for (const metadata of allMetadata) {
      metadata.set(this.resolver(values, metadata.resolverOptions));
    }
  }
  /**
   * Sets a metadata value for the page
   *
   * You can specify which metadata elements will be changed by using the
   * JSON Path that you would use if using {@link NgxMetaService.set} API
   *
   * @remarks
   * For instance, if you want to just set the title of the page. You'd set it
   * with {@link NgxMetaService.set} API like this:
   *
   * ```typescript
   * this.ngxMetaService.set({
   *  title: 'foo'
   * })
   * ```
   *
   * But rest of metadata would be removed
   *
   * To only set the `title`, you can use this API:
   * ```typescript
   * this.ngxMetaService.setOne('title', 'foo')
   * ```
   *
   * For more information check the {@link https://ngx-meta.dev/guides/set-metadata-using-service/ | service guide docs}
   *
   * @param globalOrJsonPath - Looks for metadata managers whose global matches
   *                           this argument. Or whose JSON path matches this
   *                           argument.
   * @param value - Value to set for matching metadata elements
   */
  setOne(globalOrJsonPath, value) {
    const managers = this.registry.findByGlobalOrJsonPath(globalOrJsonPath);
    /* istanbul ignore if */
    if (ngDevMode && [...managers].length === 0) {
      console.warn(_formatDevMessage('no metadata managers found for global or JSON Path', {
        module: _MODULE_NAME,
        value: globalOrJsonPath
      }));
    }
    for (const manager of managers) {
      manager.set(value);
    }
  }
  /**
   * Clears all managed metadata elements of the current page
   */
  clear() {
    this.set();
  }
  static {
    this.ɵfac = function NgxMetaService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMetaService)(i0.ɵɵinject(MetadataRegistry), i0.ɵɵinject(METADATA_RESOLVER));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxMetaService,
      factory: NgxMetaService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMetaService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: MetadataRegistry
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [METADATA_RESOLVER]
    }]
  }], null);
})();

/**
 * Logs an error message about a URL not being HTTP or HTTPs
 *
 * Useful to warn developers about some metadata that requires absolute HTTP
 * or HTTPs URLs
 *
 * MUST be used with `ngDevMode` so that this message only runs in development
 *
 * @internal
 */
/* istanbul ignore next https://github.com/istanbuljs/istanbuljs/issues/719 */
const _maybeNonHttpUrlDevMessage = (url, opts) => {
  const urlStr = url?.toString();
  if (urlStr && !(urlStr.startsWith('http') || urlStr.startsWith('https'))) {
    console.error(_formatDevMessage('URL must be absolute and use either http or https', opts));
  }
};

/**
 * Logs a warn message when a value string exceeds a length threshold
 *
 * Useful to warn developers about some metadata improperly used
 *
 * MUST be used with `ngDevMode` so that this message only runs in development
 *
 * @internal
 */
/* istanbul ignore next https://github.com/istanbuljs/istanbuljs/issues/719 */
const _maybeTooLongDevMessage = (value, maxLength, opts) => {
  if (value && value.length > maxLength) {
    console.warn(_formatDevMessage(`exceeds recommended size of ${maxLength} chars`, opts));
  }
};

// Main providers

/**
 * Generated bundle index. Do not edit.
 */

export { NgxMetaCoreModule, NgxMetaMetaService, NgxMetaMetadataLoaderModule, NgxMetaMetadataManager, NgxMetaService, _COMPOSED_KEY_VAL_META_DEFINITION_DEFAULT_SEPARATOR, _GLOBAL_APPLICATION_NAME, _GLOBAL_CANONICAL_URL, _GLOBAL_DESCRIPTION, _GLOBAL_IMAGE, _GLOBAL_LOCALE, _GLOBAL_TITLE, _HEAD_ELEMENT_UPSERT_OR_REMOVE, _KEY_ATTRIBUTE_NAME, _KEY_ATTRIBUTE_PROPERTY, _NgxMetaRouteValuesService, _VAL_ATTRIBUTE_CONTENT, __HEAD_ELEMENT_UPSERT_OR_REMOVE_FACTORY, __HEAD_ELEMENT_UPSERT_OR_REMOVE_PROVIDER, _formatDevMessage, _makeMetadataManager, _makeMetadataResolverOptions, _maybeNonHttpUrlDevMessage, _maybeTooLongDevMessage, makeComposedKeyValMetaDefinition, makeKeyValMetaDefinition, makeMetadataManagerProviderFromSetterFactory, provideNgxMetaCore, provideNgxMetaMetadataLoader, withNgxMetaDefaults };
